<template>
  <c-card title="Daftar">
    <template slot="action">
      <b-button v-if="cP(23)" :to="{name:'partners-create'}" variant="primary"><feather-icon icon="PlusIcon"></feather-icon> Tambah</b-button>
    </template>
    <pagination-table ref="table" endpoint="v1/payment_partners/index" :fields="fields">
      <template v-slot:id="{item}">
        <b-button-group size="sm">
          <b-button v-if="cP(24)" variant="primary" :to="{name:'partners-edit',params:{id:item.id}}"><feather-icon icon="EditIcon"></feather-icon></b-button>
          <b-button v-if="cP(25)" variant="danger" @click="confirmDelete(item)"><feather-icon icon="TrashIcon"></feather-icon></b-button>
        </b-button-group>
      </template>
    </pagination-table>
  </c-card>
</template>

<script>
import PaginationTable from '@/utils/components/PaginationTable.vue'
import CCard from '@/utils/components/CCard.vue'
import {BButtonGroup,BButton} from 'bootstrap-vue'

export default {
  components:{
    CCard,
    PaginationTable,
    BButtonGroup,
    BButton
  },
  data(){
    const fields = [
      {key:'code',label:'Kode',sortable:true},
      {key:'name',label:'Nama',sortable:true},
      {key:'is_active',label:'Status',sortable:true},
      {key:'id',label:'',tdClass:'d-flex justify-content-end'},
    ]
    return {
      fields
    }
  },
  methods:{
    confirmDelete(item){
      // this.confirmation(`submitDelete`,`Anda akan menghapus Pemasok <b>${item.name}</b>`,`Apakah Anda Yakin ?`,{id:item.id,name:item.name})
      this.confirmation(`submitDelete`,{text:`Anda akan menghapus Mitra Pembayaran <b>${item.name}</b>`,parameter:{id:item.id,name:item.name}})
    },
    async submitDelete({id,name}){
      try {
        await this.$http.delete(`v1/payment_partners/delete/`,{params:{id,name}})
        this.notify(`Data Berhasil Dihapus`,`OK`)
        this.$refs.table.refresh()
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style>

</style>